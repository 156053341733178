import React from 'react';
import Alert from '@mui/material/Alert';
import './Alerts.css';
const IdAlert = (props) => {
  if (props.showId) {
    return (
      <div className='alert-box'>
        <Alert
          id='id-alert'
          className='success'
          severity='info'
          onClose={() => {
            props.setShowId(false);
          }}
        >
          Download the {props.template} in the side menu. Enter <strong> {props.id} </strong>{' '}
          in the
          <strong> {props.field} </strong> field to add the {props.item}.
        </Alert>
      </div>
    );
  }
};

export default IdAlert;
