import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import "../Modals/Modals.css"
const Teleport = ({ children }) => {
  const root = document.getElementById('root');
  const el = useRef(document.createElement('div'));
  useEffect(() => {
    root.appendChild(el.current);

    return () => {
      root.removeChild(el.current);
    };
  }, [root]);

  return ReactDOM.createPortal(children, el.current);
};

export default Teleport;
