import { useNavigate } from 'react-router-dom';
import * as request from '../axios';
import axios from 'axios';

export async function getAllExams(token) {
  try {
    let data = await request.axiosGet(
      'https://rezin.pythonanywhere.com/exams/',
      token
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getExamQuestions(examSelected) {
  try {
    let data = await request.getNoAuth(
      'https://rezin.pythonanywhere.com/questions/',
      { exam: examSelected }
    );
    return data;
  } catch (error) {
    console.log(error);
    useNavigate('/');
  }
}

export async function getQuestionsCount(selectedExam) {
  try {
    let data = await request.getNoAuth(
      'https://rezin.pythonanywhere.com/exams/count',
      { exam: selectedExam }
    );
    return data;
  } catch (error) {
    console.log(error);
    useNavigate('/');
  }
}

export async function deleteExamQuestion(id, token) {
  try {
    let data = await request.axiosDelete(
      `https://rezin.pythonanywhere.com/questions/${id}`,
      token
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getQuestionAmount(exam, token) {
  try {
    let data = await request.axiosGet(
      'https://rezin.pythonanywhere.com/exams/count',
      token,
      { exam: exam }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteUserExam(id, token) {
  try {
    let data = await request.axiosDelete(
      `https://rezin.pythonanywhere.com/exams/${id}`,
      token
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function addNewExam(exam, token) {
  try {
    let data = await request.axiosPost(
      'https://rezin.pythonanywhere.com/exams/',
      exam,
      token
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function editExam(id, token, exam) {
  try {
    let data = await request.axiosPut(
      `https://rezin.pythonanywhere.com/exams/${id}`,
      exam,
      token
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateProfilePassword(token, data) {
  try {
    let response = await request.axiosPut(
      'https://rezin.pythonanywhere.com/auth/password/',
      data,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getAllResources(choice, token) {
  try {
    let response = await request.axiosGet(
      'https://rezin.pythonanywhere.com/resources/',
      token,
      { exam: choice }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteResourceById(id, token) {
  try {
    let response = await request.axiosDelete(
      `https://rezin.pythonanywhere.com/resources/${id}/`,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function addQuestionsCSV(token, file) {
  try {
    let response = await request.axiosPostWithFile(
      'https://rezin.pythonanywhere.com/questions/',
      file,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function addChoicesCSV(token, file) {
  try {
    let response = await request.axiosPostWithFile(
      'https://rezin.pythonanywhere.com/questions/choices',
      file,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function createChoices(data, token) {
  try {
    let response = await request.axiosPost(
      'https://rezin.pythonanywhere.com/questions/choices',
      data,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function createQuestionWithImage(data, token) {
  try {
    let response = await request.axiosPostWithFile(
      'https://rezin.pythonanywhere.com/questions/images',
      data,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function createQuestion(data, token) {
  try {
    let response = await request.axiosPost(
      'https://rezin.pythonanywhere.com/questions/',
      [data],
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function editQuestion(id, data, token) {
  try {
    let response = await request.axiosPutWithFile(
      `https://rezin.pythonanywhere.com/questions/${id}`,
      data,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function removeQuestionImage(id, token) {
  try {
    let response = await request.axiosDelete(
      `https://rezin.pythonanywhere.com/questions/remove/${id}`,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function editChoices(id, data, token) {
  try {
    let response = await request.axiosPut(
      `https://rezin.pythonanywhere.com/questions/choices/${id}`,
      data,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getQuestionById(id, token) {
  try {
    let response = await request.axiosGet(
      `https://rezin.pythonanywhere.com/questions/${id}`,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}
export async function properQuestionsCheck(exam, token) {
  try {
    let response = await request.axiosGet(
      `https://rezin.pythonanywhere.com/exams/validation`,
      token,
      { exam: exam }
    )
    return response;
  } catch (error) {
    console.log(error);
  }
}
export async function checkAnswer(id, data) {
  try {
    let response = await request.axiosPostWithText(
      `https://rezin.pythonanywhere.com/questions/validate/${id}`,
      data
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function forgotPassword(body) {
  try {
    let response = await axios.patch(
      'https://rezin.pythonanywhere.com/auth/forgot/',
      body
    );
    return response;
  } catch (error) {
    alert('Username or email was not found. Please try again.');
  }
}

export async function addResource(item, token) {
  try {
    let response = await request.axiosPostWithFile(
      'https://rezin.pythonanywhere.com/resources/',
      item,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}
