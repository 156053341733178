import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../App.css';
import StartExamContent from '../../components/PlayContent/Exam/StartExamContent';
import {
  getExamQuestions,
  getQuestionsCount,
} from '../../endpoints/Assessment/functions';
const StartExamPage = (props) => {
  const { examName } = useParams();
  const [allQuestions, setAllQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [count, setCount] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await questionCount(examName);
        await getAllQuestionsByExam(examName);
      } catch (error) {
        console.log('Error at StartExamPage mount', error);
      }
    };
    fetchData();
  }, [examName]);

  async function getAllQuestionsByExam(examSelected) {
    try {
      let response = await getExamQuestions(examSelected, props.token);
      setAllQuestions(response.data);
    } catch (error) {
      console.log('Error at getAllQuestionsByExam', error);
    }
    setCurrentQuestion(0);
  }
  async function questionCount(selectedExam) {
    try {
      let response = await getQuestionsCount(selectedExam, props.token);
      setCount(response.data);
    } catch (error) {
      console.log('Error at questionCount', error);
    }
  }
  if (count) {
    return (
      <div>
        <StartExamContent
          allQuestions={allQuestions}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          count={count}
          token={props.token}
        />
      </div>
    );
  }
};

export default StartExamPage;
