import React, { useEffect, useState } from 'react';
import './SearchBar.css';
import ModifyExam from '../Modals/ModifyExam';
import ResourceModal from '../Modals/ResourceModal';
import SearchIcon from '@mui/icons-material/Search';
const SearchBar = (props) => {
  const [userInput, setUserInput] = useState('');

  function searchExams() {
    let result = props.exams.filter((exam) => {
      if (exam.name.toLowerCase().includes(userInput.toLowerCase())) {
        return true;
      } else if (
        exam.description.toLowerCase().includes(userInput.toLowerCase())
      ) {
        return true;
      }
      return result;
    });
    props.setExams(result);
  }

  useEffect(() => {
    if (userInput === '') {
      props.getExams();
    }
  }, [userInput]);
  return (
    <div className='search-bar'>
      <div className='search-bar-start'>
        <div style={{ padding: '0.8rem' }}>
          <SearchIcon color='iconGray' />
        </div>
      </div>
      <div className='around-search-bar'>
        {' '}
        <input
          value={userInput}
          name='search-bar'
          onKeyDown={(e) => e.key === 'Enter' && searchExams()}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder='Search...'
        />
        {userInput !== '' ? (
          <button title='Clear Search' onClick={(e) => setUserInput('')}>
            &times;
          </button>
        ) : null}
      </div>
      <div className="search-end">
        <ResourceModal
          exams={props.exams}
          user={props.user}
          token={props.token}
        />
        <ModifyExam
          exam={null}
          user={props.user}
          token={props.token}
          getExams={props.getExams}
        />
      </div>
    </div>
  );
};

export default SearchBar;
