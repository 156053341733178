import * as request from '../axios';

export async function toggleStudy(id, data, token) {
  try {
    let response = await request.axiosPut(
      `https://rezin.pythonanywhere.com/cards/${id}/`,
      data,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteCard(id, token) {
  try {
    await request.axiosDelete(
      `https://rezin.pythonanywhere.com/cards/${id}/`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteCategory(id, token) {
  try {
    await request.axiosDelete(
      `https://rezin.pythonanywhere.com/cards/categories/${id}/`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteDeck(id, token) {
  try {
    await request.axiosDelete(
      `https://rezin.pythonanywhere.com/cards/decks/${id}/`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateCategory(id, token, name) {
  try {
    let response = await request.axiosPut(
      `https://rezin.pythonanywhere.com/cards/categories/${id}/`,
      {
        name: name,
      },
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function updateDeck(id, token, name) {
  try {
    let response = await request.axiosPut(
      `https://rezin.pythonanywhere.com/cards/decks/${id}/`,
      {
        name: name,
      },
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function addCategoryOrDeck(type, token, name) {
  const body = {
    name: name,
  };
  try {
    let response = await request.axiosPost(
      `https://rezin.pythonanywhere.com/cards/${type}/`,
      body,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function addCard(card, token) {
  try {
    let response = await request.axiosPost(
      'https://rezin.pythonanywhere.com/cards/',
      card,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function editCard(id, token, card) {
  try {
    let response = await request.axiosPut(
      `https://rezin.pythonanywhere.com/cards/${id}/`,
      card,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function addFlashCardsCSV(token, file) {
  try {
    let response = await request.axiosPostWithFile(
      'https://rezin.pythonanywhere.com/cards/',
      file,
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export default async function getAllCards(token, filter) {
  const filterParams = {};
  if (filter.deck) filterParams.deck = filter.deck;
  if (filter.category) filterParams.category = filter.category;
  if (filter.shuffle === true) filterParams.shuffle = true;
  if (filter.study === true) filterParams.study = true;
  if (filter.wrong === true) filterParams.wrong = true;

  try {
    let response = await request.axiosGet(
      'https://rezin.pythonanywhere.com/cards/',
      token,
      filterParams
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getAllCategories(token) {
  try {
    let response = await request.axiosGet(
      'https://rezin.pythonanywhere.com/cards/categories/',
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getAllDecks(token) {
  try {
    let response = await request.axiosGet(
      'https://rezin.pythonanywhere.com/cards/decks/',
      token
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}
