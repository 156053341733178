import React, { useState } from 'react';
import './DisplayExams.css';
import examImage from '../../icons/assessment image.svg';
import { ClipLoader } from 'react-spinners';
import ShareAlert from '../Alerts/ShareAlert';
import ExamCard from './ExamCard';
const DisplayExams = (props) => {
  const [examName, setExamName] = useState('');
  const [show, setShow] = useState(false);

  function copyUrl(examName) {
    const encodedExamName = encodeURIComponent(examName);
    const examUrl = `https://assessive.net/${encodedExamName}/`;
    navigator.clipboard.writeText(examUrl);
    setExamName(examName);
    setShow(true);
  }

  if (props.loading) {
    return (
      <div className='around-no-exams'>
        <div id='loading' className='exams-container'>
          <ClipLoader
            color={'#b80c09'}
            loading={props.loading}
            size={100}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    );
  } else if (!props.exams.length && !props.loading) {
    return (
      <div className='around-no-exams'>
        <div id='no-exams' className='exams-container'>
          <h1>NO EXAMS FOUND</h1>
        </div>
        <div className='home-page-footer'>
          <footer>© Copyright, Assessive</footer>
        </div>
      </div>
    );
  }
  return (
    <>
      <div>
        <ShareAlert examName={examName} show={show} setShow={setShow} />
      </div>
      <div className='around-exams'>
        <div className='exams-container'>
          {props.exams.map((exam) => {
            const examLogo = exam.logo ? exam.logo : examImage;
            return (
              <div key={exam.id}>
                <ExamCard
                  examLogo={examLogo}
                  exam={exam}
                  copyUrl={copyUrl}
                  getExams={props.getExams}
                  token={props.token}
                  user={props.user}
                />
                <hr />
              </div>
            );
          })}
        </div>
        <div className='home-page-footer'>
          <footer>© Copyright, Assessive</footer>
        </div>
      </div>
    </>
  );
};

export default DisplayExams;
