import React from 'react';
import ModifyQuestion from '../../components/QuestionPageContent/ModifyQuestion';
const AddQuestionPage = (props) => {
  return (
    <div>
      <ModifyQuestion token={props.token} />
    </div>
  );
};

export default AddQuestionPage;
