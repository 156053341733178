import React, { useState } from 'react';
import Teleport from '../Custom/Teleport';
import { ClipLoader } from 'react-spinners';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { addCategoryOrDeck } from '../../endpoints/FlashCards/functions';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
const AddDeckCategory = (props) => {
  const [addModal, setAddModal] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  function toggle() {
    if (addModal === 'active') {
      setAddModal('');
      setName('');
      setType('');
    } else {
      setAddModal('active');
    }
  }
  async function handleAdd(type, name) {
    setLoading(true);
    try {
      await addCategoryOrDeck(type, props.token, name);
      setName('');
      setType('');
      if (type === 'categories') {
        await props.getCategories();
      } else {
        await props.getDecks();
      }
    } catch (error) {
      console.log('Error occured at handleAdd', error);
    }
    setLoading(false);
  }
  return (
    <div className='flash-items'>
      <button onClick={toggle}>
        <AddBoxIcon color='black' alt='Add Deck or Category' />
        <p hidden className='description' style={{ color: '#060F16' }}>
          ADD DECK OR CATEGORY
        </p>
      </button>
      <Teleport>
        <div className={addModal} id='add-modal'>
          <div className='header'>
            <h1>ADD DECK OR CATEGORY</h1>
            <hr />
            <button className='close-modal' onClick={toggle}>
              &times;
            </button>
          </div>
          <div className='modal-body'>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel color='black'>Type</InputLabel>
              <Select
                aria-label='Select type: categories or decks'
                id='Type'
                label='Type'
                color='black'
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value=''>Choose one...</MenuItem>
                <MenuItem value='categories'>Category</MenuItem>
                <MenuItem value='decks'>Decks</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name='title'
              sx={{ width: '100%' }}
              inputProps={{ maxLength: 15 }}
              value={name}
              color='black'
              type='text'
              label='Title'
              onChange={(e) => setName(e.target.value)}
            />
            <button
              className='modal-save'
              onClick={() => handleAdd(type, name)}
              disabled={loading || type === '' || name === ''}
            >
              {!loading ? (
                'ADD'
              ) : (
                <ClipLoader
                  color={'#ffffff'}
                  loading={loading}
                  size={25}
                  aria-label='Loading Spinner'
                  data-testid='loader'
                />
              )}
            </button>
          </div>
        </div>
        <div className={addModal} id='add-overlay'></div>
      </Teleport>
    </div>
  );
};

export default AddDeckCategory;
