import React, { useEffect, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ShareIcon from '@mui/icons-material/Share';
import DeleteExamModal from '../Modals/DeleteExamModal';
import IdModal from '../Modals/IdModal';
import ModifyExam from '../Modals/ModifyExam';
import QuestionsChoiceModal from '../Modals/QuestionsChoiceModal';
import { useNavigate } from 'react-router-dom';
import { properQuestionsCheck } from '../../endpoints/Assessment/functions';
const ExamCard = (props) => {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    async function disabledButton() {
      let result = await properQuestionsCheck(props.exam.name, props.token);
      if (result === false) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
    disabledButton();
  }, [props.exam]);
  return (
    <div className='exam-flex-box' key={props.exam.id}>
      <div className='logo-container'>
        <img src={props.examLogo} alt='exam logo' />
      </div>
      <div className='middle-items'>
        <h2>{props.exam.name}</h2>
        <p style={{ marginBottom: '0.5rem' }}>
          <small>{props.exam.description}</small>
        </p>
        <div style={{ display: 'flex' }}>
          <button
            disabled={disabled}
            className='cta'
            onClick={() => navigate(`/${props.exam.name}/`)}
            title='Start Exam'
          >
            <PlayArrowIcon
              color='iconWhite'
              alt='Start Exam'
              fontSize='inherit'
            />
          </button>
          <button
            disabled={disabled}
            className='cta'
            onClick={() => {
              props.copyUrl(props.exam.name);
            }}
            title='Share Exam'
          >
            <ShareIcon color='iconWhite' alt='Share Exam' fontSize='inherit' />
          </button>
        </div>
      </div>
      <div className='end-items'>
        <DeleteExamModal
          getExams={props.getExams}
          token={props.token}
          examId={props.exam.id}
        />
        <QuestionsChoiceModal
          token={props.token}
          examId={props.exam.id}
          chosenExam={props.exam.name}
        />
        <ModifyExam
          token={props.token}
          user={props.user}
          exam={props.exam}
          getExams={props.getExams}
        />
        <IdModal examName={props.exam.name} examId={props.exam.id} />
      </div>
    </div>
  );
};

export default ExamCard;
