import React, { useState } from 'react';
import DisplayExams from '../../components/DisplayItems/DisplayExams';
import NavBar from '../../components/NavBar/NavBar';
import SearchBar from '../../components/Search/SearchBar';
import { getAllExams } from '../../endpoints/Assessment/functions';
const HomePage = (props) => {
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getExams() {
    setLoading(true);
    try {
      let data = await getAllExams(props.token);
      setExams(data);
    } catch (error) {
      console.log('Error at getExams', error);
    }
    setLoading(false);
  }
  return (
    <div className='home-page'>
      <NavBar
        isFlashHome={false}
        exams={exams}
        user={props.user}
        token={props.token}
      />
      <div className='exam-box'>
        <div>
          <SearchBar
            exams={exams}
            setExams={setExams}
            getExams={getExams}
            user={props.user}
            token={props.token}
          />
        </div>
        <div>
          {' '}
          <DisplayExams
            loading={loading}
            user={props.user}
            token={props.token}
            getExams={getExams}
            exams={exams}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
