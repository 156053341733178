import React, { useState } from 'react';
import './DashboardView.css';
import { ClipLoader } from 'react-spinners';
import DeleteModal from '../Modals/DeleteModal';
import IdAlert from '../Alerts/IdAlert';
import Title from '../Custom/Title';
import EditIcon from '@mui/icons-material/Edit';
import {
  deleteCategory,
  deleteDeck,
  updateCategory,
  updateDeck,
} from '../../endpoints/FlashCards/functions';
const DashboardView = (props) => {
  const [showId, setShowId] = useState(false);
  const [c_or_d, setC_or_d] = useState('');
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false);
  async function handleDeleteCategory(id) {
    setLoading(true);
    try {
      await deleteCategory(id, props.token);
      await props.getCategories();
    } catch (error) {
      console.log('Error occured at handleDeleteCategory', error);
    }
    setLoading(false);
  }
  async function handleDeleteDeck(id) {
    setLoading(true);
    try {
      await deleteDeck(id, props.token);
      await props.getDecks();
    } catch (error) {
      console.log('Error occured at handleDeleteDeck', error);
    }
    setLoading(false);
  }
  async function handleUpdateCategory(id, newName) {
    try {
      await updateCategory(id, props.token, newName);
      await props.getCategories();
    } catch (error) {
      console.log('Error occured at handleUpdateCategory', error);
    }
  }
  async function handleUpdateDeck(id, newName) {
    try {
      await updateDeck(id, props.token, newName);
      await props.getDecks();
    } catch (error) {
      console.log('Error occured at handleUpdateDeck', error);
    }
  }
  const CardSection = ({ items, type, updateFunction, deleteFunction }) => {
    const [editableItemId, setEditableItemId] = useState(null);

    const handleEditClick = (itemId) => {
      setEditableItemId(itemId);
    };
    return (
      <>
        <h2 className='heading'>{type.toUpperCase()}</h2>
        <div id={type.toLowerCase()}>
          {items.map((item) => (
            <div key={item.id}>
              <div
                className='deck-card'
                onClick={() => {
                  if (editableItemId !== item.id) {
                    if (type === 'categories') {
                      props.setFilter({ ...props.filter, category: item.name });
                    } else {
                      props.setFilter({ ...props.filter, deck: item.name });
                    }
                  }
                }}
              >
                <Title
                  id={item.id}
                  text={item.name}
                  onEdit={updateFunction}
                  isEditing={editableItemId === item.id}
                />
              </div>
              <div className='cta-buttons'>
                <button
                  onClick={() => {
                    setC_or_d(type === 'categories' ? 'category' : 'deck');
                    setId(item.id);
                    setShowId(true);
                  }}
                  title='Reveal Id'
                >
                  ID
                </button>
                <button
                  title='Edit Text'
                  onClick={() => handleEditClick(item.id)}
                >
                  <EditIcon sx={{ color: '#01baef', fontSize: '1.6rem' }} />
                </button>
                <DeleteModal
                  title='Delete'
                  id={item.id}
                  deleteFunction={deleteFunction}
                  loading={loading}
                  name={item.name}
                  message={`This will delete all cards with this ${
                    type === 'categories' ? 'category' : 'deck'
                  }.`}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  if (props.loading) {
    return (
      <div className='around-no-exams'>
        <div id='flash-loading' className='exams-container'>
          <ClipLoader
            color={'#b80c09'}
            loading={props.loading}
            size={100}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
        <div className='home-page-footer'>
          <footer>© Copyright, Assessive</footer>
        </div>
      </div>
    );
  } else if (
    !props.loading &&
    (props.decks.length > 0 || props.categories.length > 0)
  ) {
    return (
      <>
        <IdAlert
          id={id}
          showId={showId}
          setShowId={setShowId}
          template="Flashcard's Template"
          field={c_or_d === 'category' ? 'category_id' : 'deck_id'}
          item='flashcards'
        />

        {props.categories.length > 0 && (
          <CardSection
            items={props.categories}
            type='categories'
            updateFunction={handleUpdateCategory}
            deleteFunction={handleDeleteCategory}
          />
        )}

        {props.decks.length > 0 && (
          <CardSection
            items={props.decks}
            type='decks'
            updateFunction={handleUpdateDeck}
            deleteFunction={handleDeleteDeck}
          />
        )}

        <div className='home-page-footer'>
          <footer>© Copyright, Assessive</footer>
        </div>
      </>
    );
  } else if (
    !props.loading &&
    props.decks.length === 0 &&
    props.categories.length === 0
  ) {
    return (
      <div className='around-no-exams'>
        <div id='no-exams' className='exams-container'>
          <h1>NO ITEMS FOUND</h1>
        </div>
        <div className='home-page-footer'>
          <footer>© Copyright, Assessive</footer>
        </div>
      </div>
    );
  }
};

export default DashboardView;
