import { Alert } from '@mui/material';
import React from 'react';
const ErrorAlert = ({ content, showError, setShowError }) => {
  if (showError) {
    return (
      <div className='alert-box'>
        <Alert
          className='error general-error'
          severity='error'
          onClose={() => {
            setShowError(false);
          }}
        >
          {content}
        </Alert>
      </div>
    );
  }
};

export default ErrorAlert;
