import React, { useState } from 'react';
import Teleport from '../Custom/Teleport';
import ErrorAlert from '../Alerts/ErrorAlert';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  addChoicesCSV,
  addQuestionsCSV,
} from '../../endpoints/Assessment/functions';
import { addFlashCardsCSV } from '../../endpoints/FlashCards/functions';
import { VisuallyHiddenInput } from '../Custom/InputFile';
import { FilePresent, FileUpload } from '@mui/icons-material';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
const UploadCSVModal = (props) => {
  const [uploadModal, setUploadModal] = useState('');
  const [type, setType] = useState('');
  const [showError, setShowError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  let inputFile = document.getElementsByClassName('csv-file');

  function toggleUploadModal() {
    if (!props.isFlashHome && props.exams.length === 0) {
      alert('Try adding an exam first');
    } else if (uploadModal === 'active') {
      setUploadModal('');
      setType('');
      setSelectedFile(null);
      setShowError(false);
    } else {
      setUploadModal('active');
    }
  }

  async function saveCSV(event) {
    event.preventDefault();
    let object = { file: selectedFile };
    try {
      if (type === 'questions' && object.file && inputFile[0].value !== '') {
        await addQuestionsCSV(props.token, object);
      } else if (
        type === 'choices' &&
        object.file &&
        inputFile[0].value !== ''
      ) {
        await addChoicesCSV(props.token, object);
      } else {
        await addFlashCardsCSV(props.token, object);
        await props.getCards(props.filter);
      }
      inputFile[0].value = '';
      setType('');
      toggleUploadModal();
    } catch (error) {
      setShowError(true);
    }
  }
  return (
    <div>
      <div>
        <button title='Upload CSV' onClick={() => toggleUploadModal()}>
          <FileUploadIcon color='primary' />
          <p hidden className='description' style={{ color: '#0B4F6C' }}>
            {!props.isFlashHome
              ? 'UPLOAD QUESTIONS OR CHOICES'
              : 'UPLOAD FLASHCARDS'}
          </p>
        </button>
      </div>
      <Teleport>
        <div className={uploadModal} id='upload-modal'>
          <div className='header'>
            <h1>UPLOAD CSV</h1>
            <hr />
            <button onClick={() => toggleUploadModal()} className='close-modal'>
              &times;
            </button>
          </div>
          <form onSubmit={saveCSV} name='upload-csv-form'>
            <div className='modal-body'>
              <ErrorAlert
                showError={showError}
                setShowError={setShowError}
                content={'An error occurred. Please try again.'}
              />
              <div className='container-items'>
                {!props.isFlashHome ? (
                  <FormControl sx={{ minWidth: 180 }}>
                    <InputLabel color='black'>File Type</InputLabel>
                    <Select
                      aria-label='select file type'
                      label='File Type'
                      color='black'
                      value={type}
                      id='file-type'
                      error={showError}
                      className='file-type'
                      onChange={(e) => setType(e.target.value)}
                    >
                      <MenuItem value=''>Choose one...</MenuItem>
                      <MenuItem value='questions'>New Questions</MenuItem>
                      <MenuItem value='choices'>New Choices</MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
                <Button
                  className='csv-file'
                  component='label'
                  sx={{ minWidth: '5rem', height: '3.5rem' }}
                  variant='contained'
                  htmlFor='file-input'
                  color='primary'
                >
                  {selectedFile ? <FilePresent /> : <FileUpload />}
                </Button>
                <VisuallyHiddenInput
                  type='file'
                  id='file-input'
                  accept='.csv'
                  onChange={(event) => setSelectedFile(event.target.files[0])}
                />
                <button
                  type='submit'
                  className='modal-save'
                  disabled={
                    !selectedFile || (!props.isFlashHome && type === '')
                  }
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className={uploadModal} id='upload-overlay'></div>
      </Teleport>
    </div>
  );
};

export default UploadCSVModal;
