import React, { useState } from "react";
import "./Modals.css";
import Teleport from '../Custom/Teleport';

const IdModal = (props) => {
  const [idModal, setIdModal] = useState("");

  function toggleIdModal() {
    if (idModal === "active") {
      setIdModal("");
    } else {
      setIdModal("active");
    }
  }
  return (
    <div>
      <button
        onClick={() => toggleIdModal()}
        style={{ backgroundColor: "#b80c09", fontSize: "10px"}}
        title="Reveal Exam ID"
      >
        <b>ID</b>
      </button>
      <Teleport>
      <div className={idModal} id="exam-id-modal">
        <div className="header">
          <h1>EXAM ID</h1>
          <hr />
          <button onClick={() => toggleIdModal()} className="close-modal">
            &times;
          </button>
        </div>
        <div className="modal-body">
          <p style={{marginTop: '1rem'}}>
            The ID for this exam is <b>{props.examId}</b>. Provide this number
            is the "exam_id" field in the questions csv file.
          </p>
        </div>
      </div>
      <div className={idModal} id="exam-id-overlay"></div>
      </Teleport>
    </div>
  );
};

export default IdModal;
