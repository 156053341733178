import React, { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import useCustomForm from '../../hooks/useCustomForm';
import './UserAccess.css';
import Logo from '../../icons/logo.png';
import { TextField, Button } from '@mui/material';
const RegisterContent = () => {
  const { registerUser } = useContext(AuthContext);
  const defaultValues = {
    username: '',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  };
  const [formData, handleInputChange, handleSubmit] = useCustomForm(
    defaultValues,
    registerUser
  );
  return (
    <span className='access-container'>
      <div className='form-container'>
        <form onSubmit={handleSubmit} name="register-form">
          <div className='access-header'>
            <h2 className='form-items'>REGISTER</h2>
            <div>
              <img src={Logo} alt='Assessive Logo' />
            </div>
          </div>
          <p className='note' style={{ marginBottom: '0rem' }}>
            NOTE: Your password needs letters, numbers, and special characters!
          </p>
          <div className='name-container'>
            <TextField
              label='First Name'
              name='firstName'
              color='black'
              required
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <TextField
              label='Last Name'
              name='lastName'
              color='black'
              required
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </div>

          <TextField
            label='Email'
            type='email'
            name='email'
            color='black'
            required
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            label='Username'
            name='username'
            color='black'
            required
            value={formData.username}
            onChange={handleInputChange}
          />
          <TextField
            label='Password'
            type='password'
            name='password'
            required
            color='black'
            value={formData.password}
            onChange={handleInputChange}
          />
          <Button
            color='error'
            variant='contained'
            size='large'
            type='submit'
            disabled={Object.values(formData).some((x) => x === '')}
          >
            REGISTER
          </Button>
          <Button color='primary' text href='/login'>
            LOGIN
          </Button>
        </form>
      </div>
    </span>
  );
};

export default RegisterContent;
