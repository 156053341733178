import React, { useContext, useState } from 'react';
import './NavBar.css';
import logo from '../../icons/logo.png';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import ProfileModal from '../Modals/ProfileModal';
import UploadCSVModal from '../Modals/UploadCSVModal';
import AddDeckCategory from '../Modals/AddDeckCategory';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LogoutIcon from '@mui/icons-material/Logout';
const NavBar = (props) => {
  const { logoutUser } = useContext(AuthContext);
  const [navBar, setNavBar] = useState('');
  const menuItems = document.getElementsByClassName('description');
  const [hamburger, setHamburger] = useState('hamburger');
  // MENU SHOW AND COLLAPSE FUNCTIONALITY
  function toggleMenu() {
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].hidden === true) {
        menuItems[i].hidden = false;
        setNavBar('active');
        setHamburger('hamburger open');
      } else {
        menuItems[i].hidden = true;
        setNavBar('');
        setHamburger('hamburger');
      }
    }
  }

  function downloadCSV(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    link.click();
  }

  function handleSharedDoc() {
    const sharedDoc = props.isFlashHome
      ? 'https://docs.google.com/spreadsheets/d/1P_Xs24AJ_tVRLn9XQGHbft1lVT_1qO16ACVWaSqHmVI/export?gid=0&format=csv'
      : 'https://docs.google.com/spreadsheets/d/13UuWeegWOJHukJ-nIFRXKdt7lnEk6ztTnGK0x0_ata4/export?gid=0&format=csv';
    const sharedName = props.isFlashHome
      ? 'new_flashcards_upload'
      : 'new_questions_upload';
    downloadCSV(sharedDoc, sharedName);
  }
  return (
    <div>
      <ul id='nav-bar' className={navBar} style={{ paddingLeft: '0' }}>
        <li className='part-one'>
          <Link to='/services/'>
            <img
              src={logo}
              alt='Assessive Logo'
              className={props.isFlashHome ? 'logo flash-card-logo' : 'logo'}
            />
          </Link>
          <button
            onClick={() => toggleMenu()}
            id='menu-btn'
            className={hamburger}
            title='Toggle Menu'
          >
            <span className='hamburger-top'></span>
            <span className='hamburger-middle'></span>
            <span className='hamburger-bottom'></span>
          </button>
        </li>
        <li className='part-two'>
          {!props.isFlashHome ? (
            <ProfileModal user={props.user} token={props.token} />
          ) : null}
          {props.isFlashHome ? (
            <AddDeckCategory
              getDecks={props.getDecks}
              getCategories={props.getCategories}
              user={props.user}
              token={props.token}
            />
          ) : null}
          <UploadCSVModal
            isFlashHome={props.isFlashHome}
            getCards={props.getCards}
            filter={props.filter}
            exams={props.exams}
            user={props.user}
            token={props.token}
          />
          <div>
            <button
              title={
                !props.isFlashHome
                  ? 'Questions Template'
                  : 'Flashcards Template'
              }
              onClick={() => {
                handleSharedDoc();
              }}
            >
              <FileDownloadIcon
                color='secondary'
                alt={
                  !props.isFlashHome
                    ? 'Questions Template'
                    : 'Flashcards Template'
                }
              />
              <p hidden className='description' style={{ color: '#01BAEF' }}>
                {!props.isFlashHome ? 'QUESTIONS' : 'FLASHCARDS'} CSV TEMPLATE
              </p>
            </button>
          </div>
          {!props.isFlashHome ? (
            <div>
              <button
                title='Choices Template'
                onClick={() =>
                  downloadCSV(
                    'https://docs.google.com/spreadsheets/d/1f-XDYhpA-TTB9EdKS_XiUOSvDF-m-RBR0Agcq7iZEkk/export?gid=0&format=csv',
                    'new_choices_upload'
                  )
                }
              >
                <FileDownloadIcon color='error' alt='Choices Template' />
                <p hidden className='description' style={{ color: '#B80C09' }}>
                  CHOICES CSV TEMPLATE
                </p>
              </button>
            </div>
          ) : null}
        </li>
        <li className='part-three'>
          <button title='Log Out' onClick={logoutUser}>
            <LogoutIcon color='black' />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default NavBar;
