import './EditOrCreateQuestion.css';
import React, { useEffect, useState } from 'react';
import Logo from '../../icons/logo.png';
import {
  editQuestion,
  removeQuestionImage,
  editChoices,
  getQuestionById,
  createChoices,
  createQuestionWithImage,
  createQuestion,
} from '../../endpoints/Assessment/functions';
import { useParams } from 'react-router-dom';
import { Box, Checkbox, IconButton, TextField, Button } from '@mui/material';
import {
  AddPhotoAlternate,
  Close,
  HomeRounded,
  Save,
} from '@mui/icons-material';
import { VisuallyHiddenInput } from '../Custom/InputFile';
import { ClipLoader } from 'react-spinners';

const ModifyQuestion = (props) => {
  const { examId } = useParams();
  const { questionId } = useParams();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(5);
  const [question, setQuestion] = useState(
    examId
      ? {
          exam_id: parseInt(examId),
          question: '',
          correct_answer: '',
        }
      : {}
  );
  const [allChoices, setAllChoices] = useState([
    {
      question: questionId ? questionId : null,
      choice: '',
      position: 1,
    },
    {
      question: questionId ? questionId : null,
      choice: '',
      position: 2,
    },
    {
      question: questionId ? questionId : null,
      choice: '',
      position: 3,
    },
    {
      question: questionId ? questionId : null,
      choice: '',
      position: 4,
    },
  ]);

  useEffect(() => {
    if (questionId) {
      const fetchData = async () => {
        try {
          await getSelectedQuestion();
        } catch (error) {
          console.log('Error at ModifyQuestion mount', error);
        }
      };
      fetchData();
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      const isDesktopSize = window.innerHeight >= 625; // Check for desktop size
      setRows(isDesktopSize ? 10 : 4); // Set rows based on size
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('mou', handleResize);

    return () =>
      window.removeEventListener('resize', handleResize) ||
      window.removeEventListener('load', handleResize);
  }, []);
  async function getSelectedQuestion() {
    try {
      let result = await getQuestionById(questionId, props.token);
      setQuestion({
        exam_id: result.exam.id,
        question: result.question,
        correct_answer: result.correct_answer,
        image: result.image,
      });
      if (result.choices.length !== 0) {
        setAllChoices(result.choices);
      }
      applyPhotoToDisplay(result);
    } catch (error) {
      console.log('Error occured at getSelectedQuestion', error);
    }
  }
  function applyPhotoToDisplay(questionFound) {
    if (questionFound && questionFound.image) {
      setImage(`https://rezin.pythonanywhere.com${questionFound.image}`);
    } else {
      setImage(null);
    }
  }
  async function removePhoto() {
    let choice = window.confirm('Are you sure you want to remove the image?');
    if (choice) setImage(null);
    if (choice && questionId && question.image) {
      setQuestion({ ...question, image: null });
      try {
        await removeQuestionImage(questionId, props.token);
      } catch (error) {
        console.log('Error at removePhoto', error);
      }
    } else if (choice && !questionId && question.image) {
      delete question.image;
      setQuestion(question);
    }
  }

  function displayImage(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        setQuestion(
          Object.assign({}, question, { image: event.target.files[0] })
        );
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      setImage(null);
      delete question.image;
      setQuestion(question);
    }
  }
  async function updateChoices(choice, id) {
    try {
      await editChoices(id, choice, props.token);
    } catch (error) {
      console.log('Error occured at updateChoices', error);
    }
  }
  async function addNewChoices(choices) {
    try {
      await createChoices(choices, props.token);
    } catch (error) {
      console.log('Error occured at addNewChoices', error);
    }
  }
  function modifyChoices(position, event) {
    setAllChoices(
      allChoices.map((item) => {
        if (item.position === position) {
          return { ...item, choice: event.target.value };
        }
        return item;
      })
    );
  }

  function validateChoices(choices) {
    const hasDuplicates = choices.some(
      (obj, index, self) =>
        self.findIndex((innerObj) => innerObj.choice === obj.choice) !== index
    );
    if (hasDuplicates) {
      return true;
    } else return false;
  }
  async function editFlow() {
    if (image && image.includes('https://rezin.pythonanywhere.com/uploads')) {
      delete question.image;
      setQuestion(question);
    }
    try {
      await editQuestion(questionId, question, props.token);
      const hasId = allChoices.some((item) => item.id);
      if (hasId) {
        allChoices.map(async (item) => {
          if (item.id) {
            await updateChoices(item, item.id);
          }
          return item;
        });
      } else {
        await addNewChoices(allChoices);
      }
      alert('Successfully Updated Question');
    } catch (error) {
      console.log('Error occured at editFlow', error);
    }
  }
  async function setChoicesQuestionId(id) {
    let newArr = [...allChoices];
    for (let x = 0; x < 4; x++) {
      newArr[x].question = id;
    }
    setAllChoices(newArr);
    try {
      await createChoices(allChoices, props.token);
    } catch (error) {
      console.log('Error occured at setChoicesQuestionId', error);
    }
  }
  function resetValues() {
    alert('Your question was successfully added');
    let newArr = [...allChoices];
    for (let x = 0; x < 4; x++) {
      newArr[x].question = null;
      newArr[x].choice = '';
    }
    delete question.image;
    setQuestion(question);
    setQuestion({
      ...question,
      question: '',
      correct_answer: '',
    });
    setImage(null);
  }
  async function createFlow() {
    try {
      if (image) {
        let result = await createQuestionWithImage(question, props.token);
        if (result) {
          let questionId = parseInt(result.id);
          setChoicesQuestionId(questionId);
        }
      } else {
        let result = await createQuestion(question, props.token);
        if (result) {
          let questionId = parseInt(result[0].id);
          setChoicesQuestionId(questionId);
        }
      }
      resetValues();
    } catch (error) {
      alert('Make sure all your values are entered');
      console.log('Error occured at saveChanges CreateQuestion', error);
    }
  }
  async function handleSubmit() {
    let checkDuplicates = validateChoices(allChoices);
    if (!checkDuplicates) {
      setLoading(true);
      if (questionId) {
        try {
          await editFlow();
        } catch (error) {
          console.log('Error at editQuestion handleSubmit', error);
        }
      } else {
        try {
          await createFlow();
        } catch (error) {
          console.log('Error at editQuestion createFlow', error);
        }
      }
      setLoading(false);
    } else {
      alert('You have duplicate choices');
    }
  }
  return (
    <div>
      <div className='edit-or-create-container'>
        <div>
          <Button
            href='/'
            variant='contained'
            color='error'
            className='home-button button-link'
            startIcon={<HomeRounded />}
          >
            HOME
          </Button>
        </div>
        <div className='question-text-box'>
          <div className='add-image-box'>
            {image ? (
              <IconButton
                size='small'
                title='Remove Image'
                className='remove-image-button'
                onClick={() => removePhoto(questionId)}
              >
                <Close alt='delete upload image' fontSize='small' />
              </IconButton>
            ) : null}
            <IconButton
              component='label'
              title='Add a question image'
              size='small'
              htmlFor='upload-photo'
            >
              <AddPhotoAlternate fontSize='small' />
            </IconButton>
            <VisuallyHiddenInput
              id='upload-photo'
              type='file'
              accept='image/*'
              onChange={(e) => displayImage(e)}
            />
          </div>
          <TextField
            label='Question'
            name='question'
            InputLabelProps={{ shrink: question.question ? true : false }}
            inputProps={{ maxLength: 275 }}
            sx={{ width: '100%' }}
            rows={rows}
            multiline
            color='black'
            value={question.question}
            onChange={(e) =>
              setQuestion({ ...question, question: e.target.value })
            }
          />
        </div>
        <div className='choices'>
          {allChoices.map((item) => {
            return (
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}
                key={item.position.toLocaleString()}
              >
                <TextField
                  name={`choice-${item.position}`}
                  label={`Choice ${item.position}`}
                  className='choice'
                  value={item.choice || ''}
                  color='black'
                  type='text'
                  onChange={(e) => modifyChoices(item.position, e)}
                  inputProps={{ maxLength: 126 }}
                />
                <Checkbox
                  checked={
                    item.choice === question.correct_answer &&
                    question.correct_answer !== '' &&
                    item.choice !== ''
                  }
                  size='small'
                  title='Correct Answer'
                  color='black'
                  onChange={() =>
                    setQuestion({ ...question, correct_answer: item.choice })
                  }
                />
              </Box>
            );
          })}
        </div>
        <div>
          <Button
            className='submit-changes-button'
            onClick={() => handleSubmit()}
            variant='contained'
            color='primary'
            startIcon={!loading ? <Save /> : null}
            disabled={
              !question.question ||
              !question.correct_answer ||
              allChoices.some((item) => item.choice === '')
                ? true
                : false || loading
            }
          >
            {loading ? (
              <ClipLoader
                color={'#ffffff'}
                loading={loading}
                size={25}
                aria-label='Loading Spinner'
              />
            ) : (
              'SAVE'
            )}
          </Button>
        </div>
      </div>
      <div className='right-content'>
        <img className='logo' src={Logo} alt='Assessive Logo' />

        <div className='contain-content'>
          <div className='question-display'>
            {image ? <img src={image} alt='question visual' /> : null}
            <p>{question.question}</p>
          </div>
          <div className='choices-display'>
            {allChoices.map((item, index) => {
              return (
                <p key={index.toString()} style={{ marginBottom: '0.5rem' }}>
                  {' '}
                  <input type='radio' />
                  {item.choice || ''}
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <div className='question-footer'>
        <footer>© Copyright, Assessive</footer>
      </div>
      <div className='screen-overlay'>
        <h1>SCREEN IS TOO SMALL FOR THIS FEATURE</h1>
      </div>
    </div>
  );
};
export default ModifyQuestion;
