import React, { useEffect, useState } from 'react';
import Teleport from '../Custom/Teleport';
import './Modals.css';
import EditIcon from '@mui/icons-material/Edit';
import { addCard, editCard } from '../../endpoints/FlashCards/functions';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { cardInitialValue } from '../../context/initialValues';
const ModifyCard = (props) => {
  const [show, setShow] = useState('');
  const [cardContent, setCardContent] = useState(
    cardInitialValue(props.categories, props.decks)
  );
  function toggle() {
    if (show === 'active') {
      setShow('');
      setCardContent({
        ...cardContent,
        question: '',
        answer: '',
        hint: '',
      });
    } else {
      setShow('active');
    }
  }
  async function saveChanges(card) {
    try {
      if (card.id) {
        await editCard(card.id, props.token, card);
      } else {
        await addCard([card], props.token);
      }
      await props.getCards(props.filter);
      toggle();
    } catch (error) {
      console.log('Error occured at saveChanges ModifyCard.jsx', error);
    }
  }

  function fillFields() {
    if (props.card.id) {
      setCardContent({
        id: props.card.id,
        category_id: props.card.category.id,
        deck_id: props.card.deck.id,
        question: props.card.question,
        answer: props.card.answer,
        hint: props.card.hint,
      });
    }
  }

  useEffect(() => {
    fillFields();
  }, [props.card]);

  return (
    <>
      <>
        {props.card.id ? (
          <button title='Edit Card' onClick={() => toggle()}>
            <EditIcon sx={{ color: '#01baef', fontSize: '1.8rem' }} />
          </button>
        ) : (
          <Button
            title='Add Card'
            size='large'
            className='add-card'
            sx={{ color: '#0b4f6c' }}
            onClick={() => toggle()}
          >
            <Add fontSize='large' />
            <Typography sx={{ fontWeight: 300, fontSize: 15 }} variant='h6'>
              Add Card
            </Typography>
          </Button>
        )}{' '}
      </>
      <Teleport>
        <div className={show} id='card-modal'>
          <div className='header'>
            <h1>{cardContent.id ? 'EDIT CARD' : 'ADD CARD'}</h1>
            <hr />
            <button onClick={() => toggle()} className='close-modal'>
              &times;
            </button>
          </div>
          <div className='modal-body'>
            <div className='top-items'>
              <FormControl sx={{ minWidth: 190, width: '100%' }}>
                <InputLabel color='black'>Category</InputLabel>
                <Select
                  aria-label='select category'
                  id='select-category'
                  label='Category'
                  color='black'
                  value={cardContent.category_id || 0}
                  onChange={(e) =>
                    setCardContent({
                      ...cardContent,
                      category_id: e.target.value,
                    })
                  }
                >
                  <MenuItem value={0}>Select Category</MenuItem>
                  {props.categories.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 190, width: '100%' }}>
                <InputLabel color='black'>Deck</InputLabel>
                <Select
                  aria-label='select deck'
                  label='Deck'
                  id='select-deck'
                  color='black'
                  value={cardContent.deck_id || 0}
                  onChange={(e) =>
                    setCardContent({
                      ...cardContent,
                      deck_id: e.target.value,
                    })
                  }
                >
                  <MenuItem value={0}>Select Deck</MenuItem>
                  {props.decks.map((deck) => {
                    return (
                      <MenuItem key={deck.id} value={deck.id}>
                        {deck.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <TextField
              name='question'
              label='Question'
              className='question-input'
              inputProps={{ maxLength: 800 }}
              sx={{ width: '100%' }}
              rows={3}
              multiline={show === 'active' ? true : false}
              color='black'
              value={cardContent.question}
              onChange={(e) =>
                setCardContent({ ...cardContent, question: e.target.value })
              }
            />
            <TextField
              label='Answer'
              name='answer'
              inputProps={{ maxLength: 100 }}
              sx={{ width: '100%' }}
              color='black'
              value={cardContent.answer}
              onChange={(e) =>
                setCardContent({ ...cardContent, answer: e.target.value })
              }
            />
            <TextField
              name='hint'
              label='Hint (optional)'
              inputProps={{ maxLength: 200 }}
              sx={{ width: '100%' }}
              color='black'
              value={cardContent.hint}
              onChange={(e) =>
                setCardContent({ ...cardContent, hint: e.target.value })
              }
            />
            <button
              onClick={() => saveChanges(cardContent)}
              disabled={
                cardContent.category_id === 0 ||
                cardContent.deck_id === 0 ||
                cardContent.question === '' ||
                cardContent.answer === ''
              }
              className='modal-save'
            >
              SAVE CHANGES
            </button>
          </div>
        </div>
        <div className={show} id='card-overlay'></div>
      </Teleport>
    </>
  );
};

export default ModifyCard;
