import React from 'react';
import './CardView.css';
import Cards from './Cards';
import ModifyCard from '../Modals/ModifyCard';
const CardView = (props) => {
  const renderNoCards = () => (
    <div id='no-cards-view'>
      <div className='no-cards'>{renderAddCard()}</div>
      {renderFooter()}
    </div>
  );

  const renderCards = () => (
    <div id='card-view'>
      {renderAddCard()}
      {props.cards &&
        props.cards.map((card) => (
          <Cards
            card={card}
            key={card.id}
            token={props.token}
            categories={props.categories}
            decks={props.decks}
            getCards={props.getCards}
            filter={props.filter}
          />
        ))}
      {renderFooter()}
    </div>
  );

  const renderAddCard = () =>
    !props.filter.study ? (
      <div className='flash-card' style={{ height: '15.1rem', overflow: 'hidden' }}>
        <div className='card-back' style={{ marginBottom: '0.5rem' }}>
          <ModifyCard
            card={{}}
            categories={props.categories}
            decks={props.decks}
            filter={props.filter}
            getCards={props.getCards}
            token={props.token}
          />
        </div>
      </div>
    ) : props.cards.length === 0 ? (
      <h1>NO CARDS FOUND</h1>
    ) : null;

  const renderFooter = () => (
    <div className='home-page-footer'>
      <footer>© Copyright, Assessive</footer>
    </div>
  );

  if (props.cards.length > 0) {
    return renderCards();
  } else return renderNoCards();
};

export default CardView;
