import React, { useState } from 'react';
import './Modals.css';
import { Link } from 'react-router-dom';
import Teleport from '../Custom/Teleport';
import IdAlert from '../Alerts/IdAlert';
import DeleteAlert from '../Alerts/DeleteAlert';
import { getExamQuestions } from '../../endpoints/Assessment/functions';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import { Delete } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const QuestionsChoiceModal = (props) => {
  const [choiceModal, setChoiceModal] = useState('');
  const [questions, setQuestions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showId, setShowId] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [id, setId] = useState('');

  function toggleChoiceModal() {
    if (choiceModal === 'active') {
      setIsEditing(false);
      setChoiceModal('');
    } else {
      setChoiceModal('active');
      getAllExamQuestions(props.chosenExam);
    }
  }
  async function getAllExamQuestions(examName) {
    try {
      let response = await getExamQuestions(examName, props.token);
      setQuestions(response.data);
    } catch (error) {
      console.log('Error occured at getAllExamQuestions', error);
    }
  }

  function showEditingItems() {
    setIsEditing(true);
  }
  return (
    <div>
      <div>
        <button
          onClick={() => toggleChoiceModal()}
          title='Add or Edit Questions'
        >
          {' '}
          <AddIcon color='iconWhite' alt='Add or edit questions' />
        </button>
      </div>
      <Teleport>
        <div className={choiceModal} id='choice-modal'>
          <div className='header'>
            <h1>{isEditing ? 'SELECT A QUESTION' : 'WHICH WILL IT BE?'}</h1>
            <hr />
            {!isEditing ? (
              <button
                onClick={() => toggleChoiceModal()}
                className='close-modal'
              >
                &times;
              </button>
            ) : (
              <button
                className='close-modal'
                id='back-button'
                onClick={() => setIsEditing(false)}
              >
                <ArrowBackIcon alt='back' color='black' />
              </button>
            )}
          </div>
          <div className='modal-body'>
            {!isEditing ? (
              <>
                {' '}
                <p style={{ marginBottom: '0' }}>
                  Would you like to edit a question or add a new question?
                </p>
                <div className='option-buttons'>
                  <div>
                    <button onClick={() => showEditingItems()} className='edit'>
                      EDIT
                    </button>
                  </div>
                  <Link to={`/create/${props.examId}/`}>
                    <button className='add'>ADD</button>
                  </Link>
                </div>
              </>
            ) : (
              <>
                {' '}
                <IdAlert
                  id={id}
                  showId={showId}
                  setShowId={setShowId}
                  template='Choices Template'
                  field='question'
                  item='choices'
                />
                <DeleteAlert
                  id={id}
                  showDelete={showDelete}
                  setShowDelete={setShowDelete}
                  getAllExamQuestions={getAllExamQuestions}
                  chosenExam={props.chosenExam}
                  token={props.token}
                />
                {questions.length > 0 ? (
                  <div className='table-wrapper'>
                    <table>
                      <thead>
                        <tr>
                          <th>Question</th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {questions.map((item) => {
                          return (
                            <tr key={item.id * 90}>
                              <td className='td-question'>{item.question}</td>
                              <td>
                                <button
                                  onClick={() =>
                                    setId(item.id) &
                                    setShowId(true) &
                                    setShowDelete(false)
                                  }
                                  style={{
                                    backgroundColor: '#b80c09',
                                    margin: '0rem',
                                  }}
                                  title='Reveal Question ID'
                                >
                                  <b>ID</b>
                                </button>
                              </td>
                              <td>
                                <Link to={`/edit/${item.id}/`}>
                                  <button style={{ margin: '0rem' }}>
                                    <ModeEditIcon alt='edit' fontSize='small' />
                                  </button>
                                </Link>
                              </td>
                              <td>
                                <button
                                  onClick={() =>
                                    setId(item.id) &
                                    setShowId(false) &
                                    setShowDelete(true)
                                  }
                                  style={{ margin: '0rem' }}
                                >
                                  <Delete
                                    color='iconWhite'
                                    fontSize='small'
                                    alt='delete'
                                  />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p style={{ marginBottom: '0' }} className='no-questions'>
                    NO QUESTIONS FOR THIS EXAM
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className={choiceModal} id='choice-overlay'></div>
      </Teleport>
    </div>
  );
};
export default QuestionsChoiceModal;
