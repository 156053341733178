import React from 'react';
import Alert from '@mui/material/Alert';
import './Alerts.css';
import { Button } from '@mui/material';
import { deleteExamQuestion } from '../../endpoints/Assessment/functions.js';
const DeleteAlert = (props) => {
  const cancelButton = (
    <Button
      key='cancel'
      color='inherit'
      className='cancel'
      onClick={() => {
        props.setShowDelete(false);
      }}
    >
      Cancel
    </Button>
  );
  const deleteButton = (
    <Button
      key='delete'
      color='inherit'
      onClick={() => {
        deleteQuestion(props.id);
      }}
    >
      Delete
    </Button>
  );
  async function deleteQuestion(id) {
    try {
      await deleteExamQuestion(id, props.token);
      props.getAllExamQuestions(props.chosenExam);
    } catch (error) {
      console.log('Error Occured at DeleteAlert.jsx', error);
    }
    props.setShowDelete(false);
  }
  if (props.showDelete) {
    return (
      <div className='alert-box'>
        <Alert
          id='id-alert'
          className='error'
          severity='error'
          action={[cancelButton, deleteButton]}
        >
          Click <strong>Delete</strong> if you are sure you would like to delete
          this question.
        </Alert>
      </div>
    );
  }
};

export default DeleteAlert;
