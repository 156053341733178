import React from 'react';
import RegisterContent from '../../components/UserAccess/RegisterContent';

const RegisterPage = () => {
  return (
    <>
      <RegisterContent />
    </>
  );
};

export default RegisterPage;
