import axios from 'axios';

export async function axiosGet(url, token, params = {}) {
  try {
    let response = await axios.get(url, {
      headers: {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    alert('There was an error getting your data. Please try again.');
  }
}

export async function axiosPost(url, data, token, params = {}) {
  try {
    let response = await axios.post(url, data, {
      headers: {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    alert('There was an error adding your item. Please try again.');
  }
}

export async function axiosPut(url, data, token, params = {}) {
  try {
    let response = await axios.put(url, data, {
      headers: {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    alert('There was an error updating your item. Please try again.');
  }
}

export async function axiosDelete(url, token, params = {}) {
  try {
    let response = await axios.delete(url, {
      headers: {
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    alert('There was an error deleting your item. Please try again.');
  }
}

export async function axiosPostWithFile(url, data, token, params = {}) {
  try {
    let response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    alert('There was an error adding your item. Please try again.');
  }
}
export async function axiosPutWithFile(url, data, token, params = {}) {
  try {
    let response = await axios.put(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    alert('There was an error adding your item. Please try again.');
  }
}

export async function axiosPostWithText(url, data, params = {}) {
  try {
    let response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'text/plain',
      },
      params: params,
    });
    return response.data;
  } catch (error) {
    alert('There was an error adding your item. Please try again.');
  }
}

export async function getNoAuth(url, params = {}) {
  try {
    let response = await axios.get(url, {
      params: params,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}
