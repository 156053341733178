import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';

// pages imported
import RegisterPage from './pages/Authenication/RegisterPage';
import LoginPage from './pages/Authenication/LoginPage';
import HomePage from './pages/Assessment/HomePage';
import useAuth from './hooks/useAuth';
import AddQuestionPage from './pages/Assessment/AddQuestionPage';
import EditQuestionPage from './pages/Assessment/EditQuestionPage';
import StartExamPage from './pages/Assessment/StartExamPage';
import FlashHome from './pages/FlashCard/FlashHome';
import ForgotPage from './pages/Authenication/ForgotPage';
import PlayHome from './pages/FlashCard/PlayHome';
import ServicesPage from './pages/ServicesPage';

function App() {
  const [user, token] = useAuth();

  return (
    <div>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <HomePage user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path='/flashcards/'
          element={
            <PrivateRoute>
              <FlashHome user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path='/flashcards/play/'
          element={
            <PrivateRoute>
              <PlayHome user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path='/create/:examId/'
          element={
            <PrivateRoute>
              <AddQuestionPage user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path='/edit/:questionId/'
          element={
            <PrivateRoute>
              <EditQuestionPage user={user} token={token} />
            </PrivateRoute>
          }
        />
        <Route
          path='/:examName/'
          element={<StartExamPage user={user} token={token} />}
        />
        <Route
          path='/services/'
          element={<ServicesPage user={user} token={token} />}
        />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/forgot-password' element={<ForgotPage />} />
      </Routes>
    </div>
  );
}

export default App;
