const queryParams = new URLSearchParams(window.location.search);

const category = queryParams.get('category');
const deck = queryParams.get('deck');

export function cardInitialValue(categories, decks) {
  const currentCategory = categories.find((c) => c.name === category);
  const currentDeck = decks.find((d) => d.name === deck);
  return {
    category_id: currentCategory ? currentCategory.id : 0,
    deck_id: currentDeck ? currentDeck.id : 0,
    question: '',
    answer: '',
    hint: '',
  };
}

export function defaultExamData(userId) {
  return {
    creator_id: userId,
    logo: '',
    name: '',
    description: '',
  };
}
