import React, { useState, useEffect } from 'react';
import './Modals.css';
import Teleport from '../Custom/Teleport';
import { addNewExam, editExam } from '../../endpoints/Assessment/functions';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button, TextField } from '@mui/material';
import { defaultExamData } from '../../context/initialValues';
const ModifyExam = (props) => {
  const [show, setShow] = useState('');
  const [examContent, setExamContent] = useState(
    defaultExamData(props.user.id)
  );

  function toggle() {
    if (show === 'active') {
      setShow('');
    } else {
      setShow('active');
    }
  }
  async function handleExamChange(exam) {
    try {
      if (exam.id) {
        await editExam(exam.id, props.token, exam);
      } else {
        await addNewExam(exam, props.token);
      }
      toggle();
      await props.getExams();
    } catch (error) {
      console.log('Error occured at handleExamChange', error);
    }
  }
  useEffect(() => {
    if (props.exam) {
      setExamContent({
        id: props.exam.id,
        creator_id: props.exam.creator.id,
        logo: props.exam.logo,
        name: props.exam.name,
        description: props.exam.description,
      });
    } else {
      setExamContent(defaultExamData(props.user.id));
    }
  }, [props.exam]);

  return (
    <div>
      <div>
        {!props.exam ? (
          <div className='search-bar-end'>
            {' '}
            <Button
              onClick={() => toggle()}
              variant='text'
              color='error'
              startIcon={<AddIcon color='error' />}
            >
              <div className='button-text'>New Exam</div>
            </Button>
          </div>
        ) : (
          <div>
            {' '}
            <button onClick={() => toggle()} title='Edit Exam'>
              {' '}
              <ModeEditIcon
                color='iconWhite'
                alt='Edit Exam'
                fontSize='small'
              />
            </button>
          </div>
        )}
        <Teleport>
          <div className={show} id='exam-modal'>
            <div className='header'>
              <h1>{props.exam ? 'EDIT EXAM' : 'ADD EXAM'}</h1>
              <hr />
              <button onClick={() => toggle()} className='close-modal'>
                &times;
              </button>
            </div>
            <div className='modal-body'>
              <TextField
                name='logo url'
                sx={{ width: '100%' }}
                label='Logo'
                value={examContent.logo}
                onChange={(e) =>
                  setExamContent({ ...examContent, logo: e.target.value })
                }
                color='black'
              />
              <TextField
                name='exam name'
                sx={{ width: '100%' }}
                label='Name'
                value={examContent.name}
                required
                onChange={(e) =>
                  setExamContent({ ...examContent, name: e.target.value })
                }
                color='black'
              />
              <TextField
                name='exam description'
                sx={{ width: '100%' }}
                label='Description'
                required
                value={examContent.description}
                onChange={(e) =>
                  setExamContent({
                    ...examContent,
                    description: e.target.value,
                  })
                }
                color='black'
              />
              <button
                disabled={
                  examContent.name === '' || examContent.description === ''
                }
                onClick={() => handleExamChange(examContent)}
                className='modal-save'
              >
                SAVE EXAM
              </button>
            </div>
          </div>
          <div className={show} id='exam-overlay'></div>
        </Teleport>
      </div>
    </div>
  );
};

export default ModifyExam;
