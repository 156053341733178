import React from 'react';
import Alert from '@mui/material/Alert';
import './Alerts.css';
const ShareAlert = (props) => {

  if (props.show) {
    return (
      <div className='alert-box'>
        {props.examName ? (
          <Alert
            className='success'
            severity='success'
            onClose={() => {
              props.setShow(false);
            }}
          >
            Your link to {props.examName} has been copied to your clipboard!
          </Alert>
        ) : (
          <Alert
            className='error'
            severity='error'
            onClose={() => {
              props.setShow(false);
            }}
          >
            Your exam does not have any questions yet.
          </Alert>
        )}
      </div>
    );
  }
};

export default ShareAlert;
