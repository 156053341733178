import React, { useState } from 'react';
import './Modals.css';
import Teleport from '../Custom/Teleport';
import { deleteUserExam } from '../../endpoints/Assessment/functions';
import { Delete } from '@mui/icons-material';
const DeleteExamModal = (props) => {
  const [deleteExamModal, setDeleteExamModal] = useState('');

  function toggleDeleteExamModal() {
    if (deleteExamModal === 'active') {
      setDeleteExamModal('');
    } else {
      setDeleteExamModal('active');
    }
  }

  async function deleteExam(id) {
    try {
      await deleteUserExam(id, props.token);
      toggleDeleteExamModal();
      await props.getExams();
    } catch (error) {
      console.log('Error occured at deleteExam', error);
    }
  }
  return (
    <div>
      <div>
        <button onClick={() => toggleDeleteExamModal()} title='Delete Exam'>
          {' '}
          <Delete color='iconWhite' fontSize='small' alt='delete exam' />
        </button>
      </div>
      <Teleport>
        <div className={deleteExamModal} id='delete-exam-modal'>
          <div className='header'>
            <h1>DELETE EXAM</h1>
            <hr />
            <button
              onClick={() => toggleDeleteExamModal()}
              className='close-modal'
            >
              &times;
            </button>
          </div>
          <div className='modal-body'>
            Are you sure you would like to delete this exam? This cannot be
            undone.
            <div className='option-buttons'>
              <button
                onClick={() => deleteExam(props.examId)}
                className='modal-save'
              >
                YES
              </button>
              <button
                onClick={() => toggleDeleteExamModal()}
                className='cancel'
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
        <div className={deleteExamModal} id='delete-exam-overlay'></div>
      </Teleport>
    </div>
  );
};

export default DeleteExamModal;
