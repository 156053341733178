import React from 'react';
import Logo from '../icons/logo.png';
import { Link } from 'react-router-dom';
const ServicesPage = (props) => {
  return (
    <div id='services-page'>
      {' '}
      <ul>
        <li>
          <Link to='/'>
            <img src={Logo} alt='Logo' />
            <h4>Assessment Creator</h4>
          </Link>
        </li>
        <li>
          <Link to='/flashcards'>
            <img src={Logo} alt='Logo' className='flash-card-logo' />
            <h4>Flash Cards Creator</h4>
          </Link>
        </li>
      </ul>
      <div className='services-footer'>
        <footer>© Copyright, Assessive</footer>
      </div>
    </div>
  );
};

export default ServicesPage;
