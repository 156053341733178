import React, { useEffect, useState } from 'react';
import './Modals.css';
import Teleport from '../Custom/Teleport';
import { ClipLoader } from 'react-spinners';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {
  addResource,
  deleteResourceById,
  getAllResources,
} from '../../endpoints/Assessment/functions';
import { Delete, FilePresent, FileUpload } from '@mui/icons-material';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { VisuallyHiddenInput } from '../Custom/InputFile';
const ResourceModal = (props) => {
  let inputFile = document.getElementsByClassName('input-file');
  let select = document.getElementsByClassName('select-tag');
  let inputName = document.getElementsByClassName('input-name');
  // TRANSFORM MODAL WHEN CLICKED
  let resourcesContainer = document.getElementsByClassName(
    'resources-container'
  );

  const [resources, setResources] = useState([]);
  const [selectedExam, setSelectedExam] = useState('');
  const [loading, setLoading] = useState(false);
  const [resourceModal, setResourceModal] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [newResource, setNewResource] = useState({
    related_exam_id: null,
    name: '',
    file: null,
  });

  useEffect(() => {
    if (resources && isAdding) {
      resourcesContainer[0].hidden = true;
    } else if (resources && !isAdding) {
      resourcesContainer[0].hidden = false;
    } else if (resources) {
      resourcesContainer[0].hidden = false;
    } else {
      resourcesContainer[0].hidden = true;
    }
  }, [resources]);

  function toggleResourceModal() {
    if (resourceModal === 'active') {
      select[0].selectedIndex = 0;
      setNewResource({
        ...newResource,
        related_exam_id: null,
        name: '',
        file: null,
      });
      if (inputName[0] && inputFile[0]) {
        inputName[0].value = '';
        inputFile[0].value = '';
      }
      setIsAdding(false);
      setResourceModal('');
      setSelectedExam('');
    } else {
      resourcesContainer[0].hidden = true;
      setResourceModal('active');
      transformContent();
    }
  }

  async function getResources(choice) {
    setSelectedExam(choice);
    if (choice === '' || isAdding) {
      resourcesContainer[0].hidden = true;
      return;
    }
    try {
      let data = await getAllResources(choice, props.token);
      if (data.length > 0) {
        setResources(data);
      } else {
        resourcesContainer[0].hidden = true;
      }
    } catch (error) {
      console.log('Error occured at getResources', error);
    }
  }

  async function deleteResource(id) {
    let decision = window.confirm(
      'Click OK if you are sure you would like to delete this resource.'
    );
    if (decision) {
      try {
        await deleteResourceById(id, props.token);
        await getResources(selectedExam);
      } catch (error) {
        console.log('Error occured at deleteResource', error);
      }
    } else {
      return;
    }
  }
  async function createResource(item) {
    if (
      item.related_exam_id === null ||
      item.file === null ||
      inputFile[0].value === ''
    ) {
      return alert('Some values are missing. Try again.');
    } else {
      setLoading(true);
      try {
        await addResource(item, props.token);
        setIsAdding(false);
        setSelectedExam('');
        getResources('');
        setNewResource({ related_exam_id: null, name: '', file: null });
      } catch (error) {
        alert('Invalid Entry');
        console.log('Error occured at createResource', error);
      }
      setLoading(false);
      inputFile[0].value = '';
      inputName[0].value = '';
    }
  }

  function changeExamNameToExamId(name) {
    let result = props.exams.find((exam) => exam.name === name);
    try {
      if (result) {
        setNewResource({ ...newResource, related_exam_id: result.id });
      }
    } catch (error) {
      return;
    }
  }
  function transformContent() {
    if (resourceModal === '') {
      setIsAdding(false);
      setSelectedExam('');
    } else {
      setIsAdding(true);
      resourcesContainer[0].hidden = true;
      changeExamNameToExamId(selectedExam);
    }
  }
  return (
    <div>
      <div>
        {' '}
        <Button
          onClick={() => toggleResourceModal()}
          variant='text'
          color='primary'
          startIcon={<FindInPageIcon color='primary' alt='View Resources' />}
        >
          <div className='button-text'>RESOURCES</div>
        </Button>
      </div>
      <Teleport>
        <div className={resourceModal} id='resource-modal'>
          <div className='header'>
            <h1>RESOURCE DETAILS</h1>
            <hr />
            <button
              onClick={() => toggleResourceModal()}
              className='close-modal'
            >
              &times;
            </button>
          </div>
          <div className='modal-body'>
            <div className='top'>
              <FormControl sx={{ minWidth: 140, maxWidth: 226 }}>
                <InputLabel color='black'>By Exam</InputLabel>
                <Select
                  aria-label='select exam to view resources'
                  color='black'
                  label='By Exam'
                  id='exam-name'
                  className='select-tag'
                  onChange={(e) =>
                    getResources(e.target.value) &&
                    changeExamNameToExamId(e.target.value)
                  }
                  sx={{
                    '& .MuiSelect-select': {
                      zIndex: 10000001,
                    },
                  }}
                  value={selectedExam}
                >
                  {props.exams.map((exam) => {
                    return (
                      <MenuItem key={exam.id * 2} value={exam.name}>
                        {exam.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {!isAdding ? (
                <button
                  className='choice-button'
                  onClick={() => transformContent()}
                >
                  ADD MODE
                </button>
              ) : null}
              {isAdding ? (
                <>
                  {' '}
                  <TextField
                    name='resource name'
                    sx={{ minWidth: 180 }}
                    color='black'
                    label='Resource Name'
                    className='input-name'
                    onChange={(e) =>
                      setNewResource({
                        ...newResource,
                        name: e.target.value,
                      })
                    }
                    value={newResource.name}
                    inputProps={{ maxLength: 15 }}
                  />
                  <Button
                    className='input-file'
                    component='label'
                    sx={{ minWidth: '5rem', height: '3.5rem' }}
                    variant='contained'
                    htmlFor='file-input-resource'
                    color='primary'
                  >
                    {newResource.file ? <FilePresent /> : <FileUpload />}
                  </Button>
                  <VisuallyHiddenInput
                    type='file'
                    id='file-input-resource'
                    onChange={(event) =>
                      setNewResource({
                        ...newResource,
                        file: event.target.files[0],
                      })
                    }
                  />
                  <button
                    id='save-button'
                    onClick={() => createResource(newResource)}
                    style={{ width: '5rem', fontSize: '0.9rem' }}
                    className='modal-save'
                    disabled={loading}
                  >
                    {loading ? (
                      <ClipLoader
                        color={'#ffffff'}
                        loading={loading}
                        size={25}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                      />
                    ) : (
                      'ADD'
                    )}
                  </button>
                </>
              ) : null}
            </div>
            <ul className='resources-container'>
              {resources.map((resource) => {
                return (
                  <li key={resource.id * 3}>
                    <div>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`https://rezin.pythonanywhere.com${resource.file}`}
                      >
                        {resource.name}
                      </a>
                      <button
                        onClick={(e) => deleteResource(resource.id)}
                        title='delete resource'
                        className='delete'
                      >
                        <Delete color='black' alt='Delete' fontSize='medium' />
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={resourceModal} id='resource-overlay'></div>
      </Teleport>
    </div>
  );
};
export default ResourceModal;
