import React from 'react';
import ModifyQuestion from '../../components/QuestionPageContent/ModifyQuestion';
const EditQuestionPage = (props) => {
  return (
    <div>
      <ModifyQuestion token={props.token} />
    </div>
  );
};

export default EditQuestionPage;
