import React, { useEffect, useState } from 'react';
import './StartExamContent.css';
import Logo from '../../../icons/logo.png';
import { useNavigate } from 'react-router-dom';
import miniLogo from '../../../icons/icon.png';
import { checkAnswer } from '../../../endpoints/Assessment/functions';
import { ExitToApp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
const StartExamContent = (props) => {
  let choiceButtons = document.getElementsByClassName('choices');
  const [alertResult, setAlertResult] = useState('');
  const [currentCount, setCurrentCount] = useState(1);
  const [validateFunctionCalled, setValidateFunctionCalled] = useState(false);
  const [finalScore, setFinalScore] = useState(0);
  const [answer, setAnswer] = useState('');
  const navigate = useNavigate();
  const [image, setImage] = useState(null);

  useEffect(() => {
    showPhoto();
  }, [props.currentQuestion]);

  function exitExam() {
    let confirmation = window.confirm(
      'Click OK if you are sure you would like to end your exam session'
    );
    if (confirmation) {
      navigate('/');
    }
  }
  function showPhoto() {
    if (props.allQuestions[props.currentQuestion]) {
      setImage(props.allQuestions[props.currentQuestion].image);
    }
  }
  function saveChoice(answerSelected) {
    setAnswer(answerSelected);
  }
  async function validate(selection, questionId) {
    if (answer !== '') {
      try {
        let result = await checkAnswer(questionId, JSON.stringify(selection));
        if (result === true) {
          setFinalScore(finalScore + 1);
          setAlertResult('correct');
        } else {
          setAlertResult('wrong');
        }

        for (let x = 0; x < choiceButtons.length; x++) {
          choiceButtons[x].disabled = true;
          if (
            choiceButtons[x].textContent.trim() ===
            props.allQuestions[props.currentQuestion].correct_answer.trim()
          ) {
            choiceButtons[x].style.border = '#015401 solid 1.5px';
          } else {
            choiceButtons[x].style.border = '#B80C09 solid 1.5px';
          }
        }
        setValidateFunctionCalled(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('You need to make a choice before checking your answer.');
    }
  }
  function changeQuestion(currentIndex) {
    if (answer !== '' && validateFunctionCalled) {
      if (currentCount <= props.count) {
        const nextQuestion = currentIndex + 1;
        props.setCurrentQuestion(nextQuestion);
        setCurrentCount(currentCount + 1);
        for (let x = 0; x < choiceButtons.length; x++) {
          choiceButtons[x].disabled = false;
          choiceButtons[x].style.border = '#060f16 solid 1px';
        }
      }
      setAnswer('');
      setAlertResult('');
      setValidateFunctionCalled(false);
    } else {
      alert('Select a choice and check your answer before you continue.');
    }
  }
  return (
    <div className='active-container'>
      <div className='around-active-exam'>
        <div className='top-part'>
          <img className='logo-image' src={Logo} alt='Assessive Logo' />
          <div id='result-alert' className={alertResult}></div>
          <IconButton
            onClick={() => exitExam()}
            className='exit-button'
            title='Exit Exam'
          >
            <ExitToApp fontSize='large' />
          </IconButton>
        </div>
        {currentCount <= props.count ? (
          <div className='middle-part'>
            {image ? (
              <img
                src={`https://rezin.pythonanywhere.com${image}`}
                alt='Add Graphic'
              />
            ) : null}
            <p className='question'>
              {props.allQuestions[props.currentQuestion]
                ? props.allQuestions[props.currentQuestion].question
                : ''}
            </p>
            {props.allQuestions[props.currentQuestion] &&
              props.allQuestions[props.currentQuestion].choices.map(
                (item, index) => {
                  return (
                    <button
                      onClick={() => saveChoice(item.choice)}
                      className={`choices ${
                        answer === item.choice ? 'highlighted' : ''
                      }`}
                      key={index.toString()}
                    >
                      <div>
                        <p style={{ marginBottom: '0rem', color: '#1e1e1e' }}>
                          {' '}
                          <input
                            className='radio'
                            onClick={() => saveChoice(item.choice)}
                            type='radio'
                          />
                          {item.choice}
                        </p>
                      </div>
                    </button>
                  );
                }
              )}{' '}
          </div>
        ) : (
          <div className='middle-part'>
            <p className='final'>
              <b>
                You got {finalScore} / {props.count} correct.
              </b>
            </p>
            <p className='final'>
              <b>Your final score is:</b>
            </p>
            <p className='final'>
              <b>{((finalScore / props.count) * 100).toFixed(0)}%</b>
            </p>
          </div>
        )}

        <div className='bottom-part'>
          <a className='around-stamp' href='/register'>
            <p className='logo-stamp' style={{ marginBottom: '0rem' }}>
              Built With <img src={miniLogo} alt='Logo' />
              <b>Assessive</b>
            </p>
          </a>
          <p style={{ marginBottom: '0rem', color: '#fbfbff' }}>
            {currentCount} out {props.count}
          </p>
          {currentCount <= props.count ? (
            validateFunctionCalled ? (
              <button onClick={() => changeQuestion(props.currentQuestion)}>
                CONTINUE
              </button>
            ) : (
              <button
                onClick={() =>
                  validate(answer, props.allQuestions[props.currentQuestion].id)
                }
              >
                CHECK
              </button>
            )
          ) : (
            <button onClick={() => window.location.reload()}>TRY AGAIN</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartExamContent;
