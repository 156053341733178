import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette: {
    primary: {
      main: '#0b4f6c',
      disabled: '#0c0f16',
    },
    secondary: {
      main: '#01baef',
      disabled: '#0c0f16',
    },
    error: {
      main: '#b80c09',
      disabled: '#0c0f16',
    },
    black: {
      main: '#0c0f16',
      disabled: '#0c0f16',
    },
    iconGray: {
      main: '#7d7d7d'
    },
    iconWhite: {
      main: '#ffffff'
    },
  },
});
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
