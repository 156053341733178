import React from 'react';
import ForgotContent from '../../components/UserAccess/ForgotContent';

const ForgotPage = () => {
  return (
    <>
      <ForgotContent />
    </>
  );
};

export default ForgotPage;
