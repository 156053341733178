import React, { useEffect } from 'react';
import './FilterCards.css';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';

const FilterCards = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      props.filter.category === '' &&
      props.filter.deck === '' &&
      props.filter.study === false
    )
      return navigate('/flashcards');
    props.getCards(props.filter);
    let route = `/flashcards`;
    let params = [];
    if (props.filter.deck !== '') params.push(`deck=${props.filter.deck}`);
    if (props.filter.category !== '')
      params.push(`category=${props.filter.category}`);
    if (props.filter.study) params.push(`study=${props.filter.study}`);
    if (props.filter.wrong) params.push(`wrong=${props.filter.wrong}`);
    if (props.filter.shuffle) params.push(`shuffle=${props.filter.shuffle}`);
    if (params.length > 0) route += `?${params.join('&')}`;
    navigate(route);
  }, [props.filter]);

  function playShuffle() {
    props.setFilter({ ...props.filter, shuffle: true });
    props.filter.shuffle = true;
    navigate('/flashcards/play/', { state: { filter: props.filter } });
  }
  return (
    <div className='filter-container'>
      <div  className="around-dropdown">
        <FormControl sx={{ width: '100%' }}>
          <InputLabel color='black'>By Category</InputLabel>
          <Select
            aria-label='select a category'
            color='black'
            id='filter-category'
            label='By Category'
            value={props.filter.category}
            onChange={(e) =>
              props.setFilter({ ...props.filter, category: e.target.value })
            }
            disabled={props.categories && props.categories.length === 0}
          >
            {props.categories.map((category) => {
              return (
                <MenuItem key={category.id} value={category.name}>
                  {category.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {props.filter.category ? (
          <ClearIcon
            onClick={() => props.setFilter({ ...props.filter, category: '' })}
            color='iconGray'
            fontSize='small'
          />
        ) : null}
      </div>
      <div className="around-dropdown">
        <FormControl sx={{ width: '100%' }}>
          <InputLabel color='black'>By Deck</InputLabel>
          <Select
            aria-label='select a deck'
            label='By Deck'
            color='black'
            value={props.filter.deck}
            id='filter-deck'
            onChange={(e) =>
              props.setFilter({ ...props.filter, deck: e.target.value })
            }
            disabled={props.decks && props.decks.length === 0}
          >
            {props.decks.map((deck) => {
              return (
                <MenuItem key={deck.id} value={deck.name}>
                  {deck.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {props.filter.deck ? (
          <ClearIcon
            onClick={() => props.setFilter({ ...props.filter, deck: '' })}
            color='iconGray'
            fontSize='small'
          />
        ) : null}
      </div>
      <div className='filter-cta'>
        <button
          onClick={(e) =>
            navigate('/flashcards/play/', { state: { filter: props.filter } })
          }
          disabled={props.cards.length === 0}
        >
          PLAY
        </button>
        <button
          onClick={(e) => playShuffle(e)}
          disabled={props.cards.length === 0}
        >
          SHUFFLE
        </button>
      </div>
      <label className='study-dropdown'>
        <Checkbox
          sx={{ '&.Mui-checked': { color: '#0b4f6c' } }}
          checked={props.filter.study}
          onChange={(e) =>
            props.setFilter({ ...props.filter, study: e.target.checked })
          }
        />
        STUDY CARDS
      </label>
    </div>
  );
};

export default FilterCards;
